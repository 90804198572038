.loading_data {
  margin-top: 16px;
}

.chart_wrapper {
  background-color: #fff;
  font-size: 12px;
  em {
    font-style: normal;
  }
  * {
    box-sizing: content-box;
  }
  .chart {
  }

  .chart_item {
    min-height: 30px;
    height: 30px;
    border-bottom: 1px solid #d9d9d9;
    overflow: hidden;

    &.is_myself {
      .bar_wrapper {
        cursor: pointer;
      }
    }

    .chart_username {
      display: table;
      width: 80px;
      height: 100%;
      padding: 0 5px;
      line-height: 1.2;
      text-align: center;
      font-size: 12px;
      color: #000000;
      word-break: break-word;
      a:hover {
        text-decoration: underline;
      }
      span {
        display: table-cell;
        vertical-align: middle;
      }
      .org-chart {
        font-size: 10px;
        color: #5c5c5c;
      }
    }

    .chart_detail {
      height: 100%;
      .period {
        position: relative;
        width: 80px;
        height: 100%;
        border-left: 1px solid #d9d9d9;
        border-right: 1px solid #d9d9d9;
        font-size: 12px;
        color: #5c5c5c;
        .period_start {
          position: absolute;
          right: 0;
          top: 12.5%;
        }
        .period_end {
          position: absolute;
          right: 0;
          bottom: 43.75%;
        }
        .line {
          margin-left: 3px;
          color: #d9d9d9;
        }
      }
      .content {
        position: relative;
        height: 100%;
        .bar_container {
          width: 930px;
          height: 100%;
          overflow: hidden;
          .bar_all {
            position: relative;
            left: 0;
            height: 100%;
            -webkit-transition: left 0.8s;
            -moz-transition: left 0.8s;
            -o-transition: left 0.8s;
            transition: left 0.8s;
          }
          .bar_wrapper {
            position: relative;
            z-index: 400;
            width: 21px;
            height: 100%;
            margin-right: 1px;
            &.is_hover {
              z-index: 500;
            }
            &.bar_wk {
              background: #f4f4f4 url(~@assets/images/bg/bg-weekend.png)
                no-repeat center 0;
            }
            &.bar_ho {
              background: #f4f4f4 url(~@assets/images/bg/bg-holiday.png)
                no-repeat center 0;
            }
            &.bar_le {
              background: #f4f4f4 url(~@assets/images/bg/bg-leave.png) no-repeat
                center 0;
            }
            &.bar_gray {
              background: #f4f4f4;
            }
            .bar {
              position: absolute;
              bottom: 2px;
              width: 13px;
              min-height: 1px;
              margin: 0 4px;
              z-index: 400;
              .bar_half {
                position: absolute;
                left: 0;
                bottom: 0;
                width: 13px;
                background: #f4f4f4 url(~@assets/images/bg/bg-leave.png)
                  no-repeat center center;
              }
            }
            .sprite-missing,
            .sprite-incomplete {
              position: relative;
              margin: -8px 4px 0;
              top: 50%;
            }
            .dot {
              position: absolute;
              bottom: 3px;
              left: 50%;
              z-index: 500;
              width: 2px;
              height: 2px;
              margin-left: -1px;
            }
            .dot_white {
              background-color: #ffffff;
            }
            .dot_red {
              background-color: #ee4416;
            }
            .exempt_line {
              position: relative;
              top: 50%;
              text-align: center;
              margin: -9px 0 0;
              color: #cdcdcd;
              cursor: default;
            }
          }
        }
      }
    }

    .last_page {
      width: 30px;
      height: 100%;
      border-right: 1px solid #d9d9d9;
      .sprite-page-last,
      .sprite-page-last-disable {
        float: left;
        margin: 5px 0 0 5px;
        cursor: pointer;
      }
      .sprite-page-last-disable {
        cursor: default;
      }
      .sprite-bag,
      .sprite-bag-exempt {
        position: relative;
        top: 50%;
        float: left;
        margin: -8px 7px 0;
      }
      .sprite-bag-exempt-disable {
        cursor: default;
      }
    }

    .next_page {
      width: 30px;
      height: 100%;
      border-left: 1px solid #d9d9d9;
      .sprite-page-next,
      .sprite-page-next-disable {
        float: left;
        margin: 5px 0 0 5px;
        cursor: pointer;
      }
      .sprite-page-next-disable {
        cursor: default;
      }
    }
  }

  .chart_data_wrapper {
    position: relative;
    width: 100%;
    height: 300px;
    border-bottom: 1px solid #d9d9d9;
    overflow: auto;
  }

  .loading_more {
    margin: 16px 0;
  }

  .load_more {
    height: 30px;
    padding: 15px 0;
    text-align: center;
    .load_more_btn {
      display: inline-block;
      width: 300px;
      height: 30px;
      line-height: 30px;
      border-radius: 3px;
      background-color: #ccc;
      cursor: pointer;
      &:hover {
        background-color: #dcdcdc;
      }
    }
  }

  .clearfix {
    &::after {
      content: '.';
      display: block;
      height: 0;
      line-height: 0;
      font-size: 0;
      visibility: hidden;
      clear: both;
    }
  }

  .fl {
    float: left;
  }

  .dashed {
    width: 11px !important;
    bottom: 3px !important;
    height: 25px;
    border: 1px dashed #d9d9d9;
  }
  .red {
    background-color: #ffd200;
  }
  .green {
    background-color: #19a93f;
  }
  .blue {
    background-color: #4a77ff;
  }
  .brown {
    background-color: #7e4637;
  }
}

.memo_modal {
  :global {
    .shopee-react-modal__content {
      overflow-y: visible;
    }

    .shopee-react-modal__body {
      overflow: visible;
    }
  }

  .other_input {
    margin-top: 16px;
  }
}

.filter_wrapper {
  padding: 8px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #d9d9d9;

  font-size: 12px;

  .legend_wrapper {
    display: flex;
    align-items: center;
  }

  .legend_item {
    margin-right: 8px;
  }

  .range_item {
    display: inline-block;
    height: 26px;
    padding: 0 8px;
    line-height: 26px;
    margin: 0 2px;
    font-size: 12px;
    font-weight: 700;
    color: #5c5c5c;
    border-radius: 16px;
    cursor: pointer;
    &:hover,
    &.selected {
      color: #ffffff;
      background-color: #403b36;
    }
    :global(.shopee-react-input__inner--small .shopee-react-input__input) {
      height: 28px;
    }
  }

  .right_wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .date_wrapper {
    margin-left: 8px;
    display: flex;
    align-items: center;
  }

  .start_date {
    width: 150px;
    margin: 0 8px;
    :global(.shopee-react-date-picker__input) {
      width: 100%;
    }
    :global(.shopee-react-input__input) {
      height: 28px;
    }
  }

  .end_date {
    width: 150px;
    margin: 0 8px;
    :global(.shopee-react-date-picker__input) {
      width: 100%;
    }
    :global(.shopee-react-input__input) {
      height: 28px;
    }
  }

  .submit_btn {
    margin-right: 8px;
  }

  .square {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 3px;
    margin-top: -2px;
    vertical-align: middle;
  }

  .red {
    background-color: #ffd200;
  }
  .green {
    background-color: #19a93f;
  }
  .blue {
    background-color: #4a77ff;
  }
}

.bar_info {
  min-width: 80px;
  p {
    margin: 2px 0;
  }
}

.popover_content {
  padding: 4px 8px !important;
}

.script_type_icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  font-size: 14px;
}
