.my_team {
  width: 100%;

  .title_wrapper {
    position: relative;
    height: 44px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
  }
  .tip {
    position: absolute;
    left: 10px;
    top: 3px;
    width: 220px;
    font-weight: 400;
    font-size: 12px;
  }
}
