$wrapper-width: 100%;
$wrapper-min-width: 320px;
$options-height: 210px;
$primary-color: #2673dd;
$wrapper-active-border-color: #b7b7b7;
.select-wrapper {
  // display: inline-flex;
  // align-items: center;
  width: $wrapper-width;
  min-width: $wrapper-min-width;
  padding: 4px 12px;
  height: 32px;
  position: relative;
  text-align: left;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  &.error {
    border-color: #ff4742;
  }
  &:hover {
    border-color: #b7b7b7;
    .clear-icon {
      visibility: visible;
    }
  }
  &[aria-expanded='true'] {
    border-color: #b7b7b7;
    .select-arrow {
      fill: #000 !important;
      transform: rotate(180deg);
    }
  }
}
.select-mutiple {
  padding-right: 36px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.placeholder {
  margin-top: 1px;
  color: #b7b7b7;
  font-size: 14px;
}
.clear-icon {
  visibility: hidden;
  position: absolute;
  fill: #b7b7b7;
  top: 8px;
  right: 32px;
  width: 16px;
  height: 16px;
  &:hover {
    fill: #6e6e6e;
  }
}
.select-arrow {
  position: absolute;
  top: 8px;
  fill: #999;
  right: 12px;
  width: 16px;
  height: 16px;
}
.options-wrapper {
  display: flex;
  padding: 8px 0;
  max-height: $options-height;
  max-width: 500px;
  overflow: auto;
}
.dropdown-box {
  max-height: 280px;
  overflow: auto;
}
.select-loading,
.no-data {
  width: $wrapper-width;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999999;
  font-size: 14px;
}
.dropdown-wrapper {
  min-width: $wrapper-min-width;
  padding: 0;
  max-width: 600px;
  overflow: auto;
}
.select-value-wrapper {
  max-width: 240px;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin-right: 4px;
  padding-left: 8px;
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
}
.select-value {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.delete-icon-wrapper {
  width: 12px;
  height: 12px;
  min-width: 12px;
  line-height: 0;
  padding: 2px;
  margin: 0 4px;
}
.delete-icon {
  width: 100%;
  height: 100%;
  fill: #999;
}
.select-item {
  position: relative;
  padding: 7px 12px;
  display: flex;
  align-items: center;

  &:hover {
    color: #333 !important;
    background-color: rgba(0, 0, 0, 0.04);
  }

  // width: 158px;
  &.is-select {
    color: #2673dd;
    .checkbox {
      border-color: #2673dd;
      background-color: #2673dd;
    }
    .has-more {
      fill: #2673dd;
    }
  }
  &.text-high-light {
    color: #2673dd;
    .has-more {
      fill: #2673dd;
    }
  }
}
.select-item-text {
  display: inline-block;
  flex: 1;
  font-size: 14px;
  padding-right: 16px;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &.high-light {
    color: #2673dd;
  }
}
.checkbox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  vertical-align: middle;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.more {
  position: absolute;
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  vertical-align: middle;
}

.icon {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 16px;
  height: 16px;
  transition: transform 0.2s;
  fill: #fff;
  display: inline-block;
  line-height: 0;
  &.has-more {
    fill: #999;
    top: 0;
    &.active {
      fill: #2673dd;
    }
  }
}

.search-input-wrapper {
  position: relative;
  display: inline-table;
  width: 100%;
  height: 32px;
  font-size: 14px;
  background-color: #fff;
}
.search-icon {
  position: relative;
  display: table-cell;
  height: 100%;
  line-height: 0;
  color: #999;
  white-space: nowrap;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}
.search-input {
  display: table-cell;
  width: 100%;
  height: 30px;
  margin-left: 8px;
  padding: 0 16px 0 0;
  color: #333;
  vertical-align: middle;
  background-color: inherit;
  border: 0;
  outline: none;
  &::placeholder {
    color: $wrapper-active-border-color;
  }
}
.check {
  position: absolute;
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: 8px;
  vertical-align: middle;
}
.search-wrapper {
  padding: 0 8px;
}

.high-light-text {
  color: $primary-color;
}

.options-search-wrapper {
  .select-item {
    width: 100%;
    cursor: pointer;
    &.is-select {
      color: $primary-color;
      .check-icon {
        fill: $primary-color;
        top: 0;
      }
    }
  }
}
