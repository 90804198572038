.my_attendance {
  width: 100%;

  .title_wrapper {
    height: 44px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 15px;
  }
}
