.navbar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 36px;
  height: 57px;
  border-bottom: 1px solid #ebebeb;
  background-color: #fff;

  .home_info {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .home_title {
    font-size: 16px;
    margin-left: 8px;
  }

  .nav_link {
    display: flex;
  }

  .icon {
    display: block;
  }

  .nav_list {
    position: absolute;
    left: -240px;
    top: 0;
    z-index: 100;
    width: 240px;
    background: rgba(0, 0, 0, 0.8);
    transition: 0.15s linear left;
    height: calc(100vh - 61px);
    overflow: auto;
  }

  .nav_item {
    padding-left: 36px;
    height: 58px;
    display: flex;
    align-items: center;
    &:first-child {
      font-size: 16px;
    }
  }

  .expand {
    left: 0;
  }

  .nav_link {
    display: flex;
    align-items: center;
  }

  .menu_display {
    margin-left: 8px;
    color: #fff;
  }
}
