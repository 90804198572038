.function_wrapper {
  width: 100%;
  padding: 24px;
  overflow: auto;
  .function_title {
    font-weight: 500;
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .tip {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px;
  }

  .filter_wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .filter_item {
    width: 320px;
    margin-right: 24px;
    margin-bottom: 24px;
  }

  .filter_label {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  :global(.shopee-react-date-picker) {
    width: 100%;
    :global(.shopee-react-date-picker__input) {
      width: 100%;
    }
  }

  :global(.shopee-react-select-options-list) {
    padding: 0 0 8px;
  }

  :global(.shopee-react-cascader) {
    width: 100%;
  }

  .submit {
    margin-top: 24px;
  }

  .reset {
    margin-left: 12px;
    margin-top: 24px;
  }

  .table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 16px;
  }

  .table_title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .action_button {
    line-height: 21px;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    white-space: pre-wrap;
    &.three {
      -webkit-line-clamp: 3;
    }
  }
}
