.sidebar {
  width: 246px;
  height: calc(100vh - 118px);
  background-color: #ececec;
  padding: 16px;
  flex-shrink: 0;

  .sidebar_wrapper {
    width: 100%;
    border: 1px solid #e3e3e3;
  }

  .icon {
    display: block;
    margin-right: 8px;
  }

  .sidebar_title {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    border-bottom: none;
    line-height: 28px;
    font-size: 12px;
    overflow: hidden;
  }

  .menu_wrapper {
    background-color: #fff;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .menu_item {
    display: flex;
    align-items: center;
    display: block;
    height: 32px;
    padding-left: 50px;
    border-bottom: 1px solid #e4e4e4;
    line-height: 32px;
    text-decoration: none;
    font-size: 12px;
    color: #282828;
    background-color: #f6f6f6;
    &.acitve {
      background-color: #75a3f3;
      color: #fff;
    }
  }

  .search {
    margin-top: 24px;
  }

  :global(.shopee-react-popover-inner) {
    overflow: auto !important;
  }
}
