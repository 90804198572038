.permission_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin: 16px;

  .permission_tip {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
  }
}
