.detail_wrapper {
  width: 100%;
  color: #333;
  overflow: auto;

  .back_wrapper {
    padding: 4px;
    background-color: #ececec;
  }

  .detail_content {
    width: 100%;
    padding: 16px;
  }

  .header_wrapper {
    display: flex;
    margin-top: 8px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }

  .title {
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
  }

  .delete {
    margin-right: 16px;
  }

  .tip {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 32px;
  }

  .info_wrapper {
    font-size: 14px;
    line-height: 16px;
  }

  .info_item {
    margin-bottom: 24px;
  }

  .detail_name {
    line-height: 16px;
    color: #666;
    margin-bottom: 8px;
  }

  .team_item {
    padding: 0;
    margin: 4px 0;
  }
}

.loading_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
