@import '~@styles/fonts.scss';

.out_wrapper {
  height: 100%;
  .content {
    display: flex;
    width: 100%;
    height: calc(100% - 118px);
  }

  .loading_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    margin: 16px;
  }
}
