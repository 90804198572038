.user_modal {
  :global(.shopee-react-dropdown) {
    width: 100%;
  }

  .form_item {
    margin-bottom: 16px;
  }

  .label {
    margin-bottom: 4px;
  }

  .required {
    display: inline-block;
    margin-right: 4px;
    font-size: 12px;
    line-height: 0;
    color: #ff4742;
  }

  .valid {
    margin-top: 4px;
    color: #ff4742;
  }

  :global(.shopee-react-modal__content) {
    overflow-y: visible !important;
  }

  :global(.shopee-react-modal__body) {
    overflow: visible !important;
  }

  :global(.shopee-react-modal__mask) {
    z-index: 100 !important;
  }
}
