* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font: 12px/1.5 Roboto, 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
}

body,
html,
#root {
  width: 100% !important;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #353535;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
