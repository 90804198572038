// Roboto字体官网：https://fonts.google.com/specimen/Roboto
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-display: swap;
  font-style: normal;
  src: url(~@assets/fonts/Roboto-Thin.ttf) format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 100;
  font-display: swap;
  font-style: italic;
  src: url(~@assets/fonts/Roboto-ThinItalic.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-display: swap;
  font-style: normal;
  src: url(~@assets/fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 300;
  font-display: swap;
  font-style: italic;
  src: url(~@assets/fonts/Roboto-LightItalic.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 400;
  font-display: swap;
  font-style: normal;
  src: url(~@assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-display: swap;
  font-style: normal;
  src: url(~@assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 500;
  font-display: swap;
  font-style: italic;
  src: url(~@assets/fonts/Roboto-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-display: swap;
  font-style: normal;
  src: url(~@assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 700;
  font-display: swap;
  font-style: italic;
  src: url(~@assets/fonts/Roboto-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-display: swap;
  font-style: normal;
  src: url(~@assets/fonts/Roboto-Black.ttf) format('truetype');
}
@font-face {
  font-family: Roboto;
  font-weight: 900;
  font-display: swap;
  font-style: italic;
  src: url(~@assets/fonts/Roboto-BlackItalic.ttf) format('truetype');
}
