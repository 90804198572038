.header {
  display: flex;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  background-color: #f1f1f1;
  .logo_info {
    display: flex;
    align-items: center;
  }
  .sea_logo {
    margin: 0 16px 0 25px;
    background: url(../../assets/logo.png) no-repeat;
    height: 60px;
    background-size: contain;
    width: 100px;
    background-position: center;
    cursor: pointer;
  }
  .login_info {
    display: flex;
    align-items: center;
  }
  .username {
    cursor: pointer;
    margin-left: 8px;
    margin-right: 8px;
  }
  .logout {
    margin-right: 16px;
  }
}
