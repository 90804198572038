.sprite { background-image: url(/workspace/src/assets/sprite.png) }
.sprite-add-label { width: 65px; height: 63px; background-position: -886px -829px; }
.sprite-add-note { width: 12px; height: 12px; background-position: -353px -792px; }
.sprite-add-s { width: 38px; height: 29px; background-position: -1423px -829px; }
.sprite-add-staff { width: 52px; height: 52px; background-position: -1077px -829px; }
.sprite-add-view { width: 43px; height: 28px; background-position: -1116px -737px; }
.sprite-add { width: 44px; height: 22px; background-position: -1279px -709px; }
.sprite-addstaff-s { width: 28px; height: 26px; background-position: -1297px -737px; }
.sprite-addstaff { width: 87px; height: 82px; background-position: -447px -709px; }
.sprite-alert { width: 12px; height: 12px; background-position: -365px -792px; }
.sprite-arrow-collapse { width: 12px; height: 7px; background-position: -410px -792px; }
.sprite-arrow-down_hover { width: 27px; height: 28px; background-position: -1159px -737px; }
.sprite-arrow-down { width: 27px; height: 28px; background-position: -1186px -737px; }
.sprite-arrow-expand { width: 12px; height: 7px; background-position: -422px -792px; }
.sprite-arrow-right { width: 15px; height: 10px; background-position: -1494px -768px; }
.sprite-arrrow-down_hover { width: 27px; height: 28px; background-position: -1213px -737px; }
.sprite-attendance-s { width: 26px; height: 26px; background-position: -1487px -829px; }
.sprite-attendance { width: 80px; height: 80px; background-position: -486px -829px; }
.sprite-bag-exempt { width: 15px; height: 15px; background-position: -622px -805px; }
.sprite-bag { width: 15px; height: 15px; background-position: -637px -805px; }
.sprite-browse-s { width: 29px; height: 26px; background-position: -1268px -737px; }
.sprite-browse { width: 87px; height: 80px; background-position: -620px -709px; }
.sprite-btn-next { width: 60px; height: 30px; background-position: -936px -737px; }
.sprite-btn-previous { width: 60px; height: 30px; background-position: -996px -737px; }
.sprite-btn-rights { width: 87px; height: 25px; background-position: -1192px -709px; }
.sprite-budget-s { width: 26px; height: 26px; background-position: -1513px -829px; }
.sprite-budget { width: 80px; height: 80px; background-position: -566px -829px; }
.sprite-cancel { width: 200px; height: 28px; background-position: -792px -709px; }
.sprite-claim-s { width: 26px; height: 26px; background-position: 0px -913px; }
.sprite-claim { width: 81px; height: 81px; background-position: -81px -829px; }
.sprite-close-big_hover { width: 11px; height: 10px; background-position: -1509px -768px; }
.sprite-close-big-cancel { width: 11px; height: 10px; background-position: -1520px -768px; }
.sprite-close-big { width: 11px; height: 10px; background-position: -1531px -768px; }
.sprite-close { width: 7px; height: 7px; background-position: -434px -792px; }
.sprite-collapse-minus { width: 14px; height: 14px; background-position: -728px -805px; }
.sprite-collapse-s { width: 9px; height: 12px; background-position: -401px -792px; }
.sprite-config-s { width: 26px; height: 27px; background-position: -1461px -829px; }
.sprite-config { width: 81px; height: 84px; background-position: 0px -829px; }
.sprite-configuration-icon { width: 16px; height: 16px; background-position: -1543px -737px; }
.sprite-cost-center { width: 19px; height: 20px; background-position: -360px -805px; }
.sprite-create { width: 200px; height: 28px; background-position: -992px -709px; }
.sprite-cross { width: 12px; height: 12px; background-position: -377px -792px; }
.sprite-default { width: 97px; height: 96px; background-position: -160px -709px; }
.sprite-delete { width: 14px; height: 16px; background-position: -1431px -863px; }
.sprite-down { width: 19px; height: 19px; background-position: -479px -805px; }
.sprite-duplicate-b { width: 63px; height: 63px; background-position: -951px -829px; }
.sprite-duplicate { width: 52px; height: 52px; background-position: -1129px -829px; }
.sprite-email { width: 16px; height: 14px; background-position: -712px -805px; }
.sprite-expand-add { width: 14px; height: 14px; background-position: -742px -805px; }
.sprite-expand-circle { width: 39px; height: 20px; background-position: -1323px -709px; }
.sprite-expand-s { width: 10px; height: 9px; background-position: -1542px -768px; }
.sprite-expand { width: 52px; height: 52px; background-position: -1181px -829px; }
.sprite-eye { width: 21px; height: 14px; background-position: -1497px -709px; }
.sprite-fav-a { width: 15px; height: 15px; background-position: -652px -805px; }
.sprite-fav { width: 15px; height: 17px; background-position: -577px -805px; }
.sprite-favorite-grey { width: 17px; height: 17px; background-position: -936px -768px; }
.sprite-favorite { width: 17px; height: 17px; background-position: -953px -768px; }
.sprite-file { width: 18px; height: 23px; background-position: -1381px -737px; }
.sprite-filter { width: 13px; height: 13px; background-position: -1481px -768px; }
.sprite-handover-s { width: 28px; height: 26px; background-position: -1325px -737px; }
.sprite-handover-to { width: 20px; height: 14px; background-position: -1518px -709px; }
.sprite-handover { width: 86px; height: 81px; background-position: -534px -709px; }
.sprite-history-s { width: 28px; height: 27px; background-position: -1240px -737px; }
.sprite-history { width: 94px; height: 83px; background-position: -353px -709px; }
.sprite-incomplete { width: 15px; height: 15px; background-position: -667px -805px; }
.sprite-label-add { width: 18px; height: 18px; background-position: -1489px -737px; }
.sprite-label-down { width: 18px; height: 16px; background-position: -1089px -768px; }
.sprite-label-expandAll { width: 18px; height: 18px; background-position: -1507px -737px; }
.sprite-label-export { width: 17px; height: 19px; background-position: -498px -805px; }
.sprite-label-grey { width: 17px; height: 14px; background-position: -1377px -768px; }
.sprite-label-member { width: 20px; height: 19px; background-position: -379px -805px; }
.sprite-label-remove { width: 14px; height: 18px; background-position: -1389px -863px; }
.sprite-label-rename { width: 22px; height: 14px; background-position: -1475px -709px; }
.sprite-label-up { width: 18px; height: 16px; background-position: -1107px -768px; }
.sprite-label { width: 17px; height: 14px; background-position: -1394px -768px; }
.sprite-leave-s { width: 26px; height: 26px; background-position: -26px -913px; }
.sprite-leave { width: 81px; height: 81px; background-position: -162px -829px; }
.sprite-left-remove_hover { width: 13px; height: 16px; background-position: -1459px -863px; }
.sprite-left-remove { width: 13px; height: 15px; background-position: -1472px -863px; }
.sprite-left { width: 6px; height: 9px; background-position: -1552px -768px; }
.sprite-link { width: 6px; height: 9px; background-position: -756px -805px; }
.sprite-lock-grey { width: 14px; height: 19px; background-position: -549px -805px; }
.sprite-lock-open-grey { width: 20px; height: 19px; background-position: -399px -805px; }
.sprite-lock-open { width: 20px; height: 19px; background-position: -419px -805px; }
.sprite-lock { width: 14px; height: 19px; background-position: -563px -805px; }
.sprite-logo_vn { width: 144px; height: 31px; background-position: -792px -737px; }
.sprite-logo { width: 1559px; height: 709px; background-position: 0px 0px; }
.sprite-mark_hover { width: 23px; height: 18px; background-position: -1399px -737px; }
.sprite-mark { width: 23px; height: 18px; background-position: -1422px -737px; }
.sprite-missing { width: 15px; height: 15px; background-position: -682px -805px; }
.sprite-more-active { width: 18px; height: 13px; background-position: -1445px -768px; }
.sprite-more { width: 18px; height: 13px; background-position: -1463px -768px; }
.sprite-no-permission { width: 96px; height: 96px; background-position: -257px -709px; }
.sprite-notes { width: 18px; height: 20px; background-position: -1539px -829px; }
.sprite-org-grey { width: 16px; height: 16px; background-position: -1233px -768px; }
.sprite-org { width: 16px; height: 16px; background-position: -1249px -768px; }
.sprite-orgchart-s { width: 26px; height: 26px; background-position: -52px -913px; }
.sprite-orgchart { width: 80px; height: 80px; background-position: -646px -829px; }
.sprite-page-last-disable { width: 20px; height: 20px; background-position: -160px -805px; }
.sprite-page-last { width: 20px; height: 20px; background-position: -180px -805px; }
.sprite-page-next-disable { width: 20px; height: 20px; background-position: -200px -805px; }
.sprite-page-next { width: 20px; height: 20px; background-position: -220px -805px; }
.sprite-payroll-s { width: 26px; height: 26px; background-position: -78px -913px; }
.sprite-payroll { width: 81px; height: 81px; background-position: -243px -829px; }
.sprite-phone { width: 14px; height: 16px; background-position: -1445px -863px; }
.sprite-profile-cancel { width: 15px; height: 15px; background-position: -697px -805px; }
.sprite-profile-done { width: 21px; height: 15px; background-position: -1412px -709px; }
.sprite-profile-edit { width: 16px; height: 16px; background-position: -1265px -768px; }
.sprite-profile-next_hover { width: 20px; height: 20px; background-position: -240px -805px; }
.sprite-profile-next { width: 20px; height: 20px; background-position: -260px -805px; }
.sprite-profile-pre_hover { width: 20px; height: 20px; background-position: -280px -805px; }
.sprite-profile-pre { width: 20px; height: 20px; background-position: -300px -805px; }
.sprite-profile-s { width: 26px; height: 26px; background-position: -104px -913px; }
.sprite-profile { width: 81px; height: 81px; background-position: -324px -829px; }
.sprite-project-s { width: 26px; height: 26px; background-position: -130px -913px; }
.sprite-project { width: 80px; height: 80px; background-position: -726px -829px; }
.sprite-remove { width: 52px; height: 52px; background-position: -1233px -829px; }
.sprite-rename { width: 52px; height: 52px; background-position: -1285px -829px; }
.sprite-report-s { width: 26px; height: 26px; background-position: -156px -913px; }
.sprite-report { width: 81px; height: 81px; background-position: -405px -829px; }
.sprite-search-adv { width: 19px; height: 13px; background-position: -1538px -709px; }
.sprite-search-empty { width: 160px; height: 120px; background-position: 0px -709px; }
.sprite-search { width: 60px; height: 30px; background-position: -1056px -737px; }
.sprite-set-org-big { width: 63px; height: 63px; background-position: -1014px -829px; }
.sprite-set-org { width: 52px; height: 52px; background-position: -1337px -829px; }
.sprite-share-grey { width: 16px; height: 16px; background-position: -1281px -768px; }
.sprite-share { width: 16px; height: 16px; background-position: -1297px -768px; }
.sprite-signoff-s { width: 26px; height: 26px; background-position: -182px -913px; }
.sprite-signoff { width: 80px; height: 80px; background-position: -806px -829px; }
.sprite-staff-deactivate { width: 17px; height: 17px; background-position: -970px -768px; }
.sprite-staff-leaving { width: 17px; height: 17px; background-position: -987px -768px; }
.sprite-staff-pending { width: 17px; height: 17px; background-position: -1004px -768px; }
.sprite-staff-test { width: 34px; height: 34px; background-position: -1389px -829px; }
.sprite-staff { width: 17px; height: 17px; background-position: -1021px -768px; }
.sprite-success { width: 18px; height: 18px; background-position: -1525px -737px; }
.sprite-tips { width: 17px; height: 17px; background-position: -1038px -768px; }
.sprite-top_hover { width: 18px; height: 16px; background-position: -1125px -768px; }
.sprite-top-add_hover { width: 18px; height: 18px; background-position: -792px -768px; }
.sprite-top-add { width: 18px; height: 18px; background-position: -810px -768px; }
.sprite-top-down_hover { width: 18px; height: 16px; background-position: -1143px -768px; }
.sprite-top-down { width: 18px; height: 16px; background-position: -1161px -768px; }
.sprite-top-duplicate_hover { width: 21px; height: 15px; background-position: -1433px -709px; }
.sprite-top-duplicate { width: 21px; height: 15px; background-position: -1454px -709px; }
.sprite-top-expandAll_hover { width: 18px; height: 18px; background-position: -828px -768px; }
.sprite-top-expandAll { width: 18px; height: 18px; background-position: -846px -768px; }
.sprite-top-export_hover { width: 17px; height: 19px; background-position: -515px -805px; }
.sprite-top-export { width: 17px; height: 19px; background-position: -532px -805px; }
.sprite-top-log_hover { width: 25px; height: 17px; background-position: -1362px -709px; }
.sprite-top-log { width: 25px; height: 17px; background-position: -1387px -709px; }
.sprite-top-mark_hover { width: 16px; height: 16px; background-position: -1313px -768px; }
.sprite-top-mark { width: 16px; height: 16px; background-position: -1329px -768px; }
.sprite-top-member_hover { width: 20px; height: 19px; background-position: -439px -805px; }
.sprite-top-member { width: 20px; height: 19px; background-position: -459px -805px; }
.sprite-top-remove_hover { width: 14px; height: 18px; background-position: -1403px -863px; }
.sprite-top-remove { width: 14px; height: 18px; background-position: -1417px -863px; }
.sprite-top-rename_hover { width: 22px; height: 18px; background-position: -1445px -737px; }
.sprite-top-rename { width: 22px; height: 18px; background-position: -1467px -737px; }
.sprite-top-signoff_hover { width: 20px; height: 20px; background-position: -320px -805px; }
.sprite-top-signoff { width: 20px; height: 20px; background-position: -340px -805px; }
.sprite-top-up_hover { width: 18px; height: 16px; background-position: -1179px -768px; }
.sprite-top-up { width: 18px; height: 16px; background-position: -1197px -768px; }
.sprite-top { width: 18px; height: 16px; background-position: -1215px -768px; }
.sprite-transfer_hover { width: 15px; height: 17px; background-position: -592px -805px; }
.sprite-transfer { width: 15px; height: 17px; background-position: -607px -805px; }
.sprite-tree-collapse_hover { width: 18px; height: 18px; background-position: -864px -768px; }
.sprite-tree-collapse { width: 18px; height: 18px; background-position: -882px -768px; }
.sprite-tree-expand_hover { width: 18px; height: 18px; background-position: -900px -768px; }
.sprite-tree-expand { width: 18px; height: 18px; background-position: -918px -768px; }
.sprite-tree-label { width: 17px; height: 17px; background-position: -1055px -768px; }
.sprite-tree { width: 17px; height: 17px; background-position: -1072px -768px; }
.sprite-user { width: 12px; height: 12px; background-position: -389px -792px; }
.sprite-validate-s { width: 28px; height: 25px; background-position: -1353px -737px; }
.sprite-validate { width: 85px; height: 77px; background-position: -707px -709px; }
.sprite-view-edit_hover { width: 16px; height: 16px; background-position: -1345px -768px; }
.sprite-view-edit { width: 16px; height: 16px; background-position: -1361px -768px; }
.sprite-view-grey { width: 17px; height: 14px; background-position: -1411px -768px; }
.sprite-view { width: 17px; height: 14px; background-position: -1428px -768px; }